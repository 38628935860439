<template>
    <section class="home-hero">
        <div class="home-hero__visual">
            <div class="home-hero__visual__bg"></div>
            <div class="home-hero__visual__frame"></div>
            <div class="home-hero__visual__image">
                <img class="home-hero__visual__image__image" src="../../images/_content/home-hero/home-hero-240822/ekler-v1.png"><!-- scr-up-small -->
                <!-- <img class="home-hero__visual__image__image scr-down-small" src="../../images/_content/home-hero/home-hero-230517/szilagyi-v4-sm.png"> -->
            </div>
            <div class="home-hero__visual__bottom"></div>
        </div>
        <header class="home-hero__content-container">
            <div class="home-hero__content-container__wrapper wrapper wrapper--wide">
                <div class="home-hero__content">
                    <div class="home-hero__tagline">A magyar virtuális sportmúzeum</div>
                    <h1 class="home-hero__title">Paralimpia 2024</h1>
                    <p class="home-hero__lead">Bemutatjuk a párizsi paralimpián résztvevő magyar sportolókat</p>
                </div>
                <div class="home-hero__cta">
                    <a href="/#/paris2024-paralimpia" class="home-hero__button button">Tovább</a>
                </div>
            </div>
        </header>
    </section>
</template>
